<template>
  <b-card>
    <validation-observer
      ref="infoRules"
      v-slot="{ invalid }"
      tag="form"
    >
      <b-row>
        <b-col
          cols="12"
        >
          <h3 class="mb-2">
            Certificates
          </h3>
        </b-col>
        <!-- Server Errors -->
        <b-col md="6">
          <validation-provider
            #default="validationContext"
            rules="required"
            name="certificate"
          >
            <b-form-group
              label="Certificate"
              label-for="certificate"
            >
              <b-form-radio-group
                id="certificates_id"
                v-model="requestForm.certificatesGroup"
                class="d-flex flex-column"
                :options="optionsStatusId"
                :state="getValidationState(validationContext)"
              />
              <b-form-invalid-feedback>{{
                validationContext.errors[0]
              }}</b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          v-for="(group, index) in selectedCertificateGroups"
          :key="index"
          md="6"
        >
          <validation-provider
            #default="validationContext"
            rules="required"
            :name="`subCertificates${index}`"
          >
            <b-form-group
              :label="`Sub Certificates for ${group.text}`"
              :label-for="`sub_certificates_${index}`"
            >
              <b-form-radio-group
                :id="`sub_certificates_${index}`"
                v-model="requestForm.certificate_id"
                class="d-flex flex-column"
                :options="group.certificates"
                trim
                :state="getValidationState(validationContext)"
                multiple
              />
              <b-form-invalid-feedback>{{
                validationContext.errors[0]
              }}</b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>

        <!-- Server Errors -->
        <b-col
          v-if="Object.values(errors).length > 0"
          cols="12"
        >
          <p
            v-for="(error, key, index) in errors"
            :key="index"
            class="text-danger small"
          >
            {{ error.toString() }}
          </p>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
          class="mt-50"
        >
          <b-button
            v-if="!loader && $route.params.id"
            variant="primary"
            class="mr-1"
            :disabled="invalid || Object.values(errors).length > 0"
            @click="SetRequest()"
          >
            Save Changes
          </b-button>
          <b-button
            v-if="!loader && !$route.params.id"
            variant="primary"
            class="mr-1"
            :disabled="invalid || Object.values(errors).length > 0"
            @click="SetRequest()"
          >
            Add
          </b-button>
          <b-button
            v-if="loader"
            variant="primary"
            disabled
            class="mr-1"
          >
            <b-spinner
              small
              type="grow"
            />
            Loading...
          </b-button>
        </b-col>
      </b-row>
    </validation-observer>
  </b-card>
</template>

<script>
import { ref } from '@vue/composition-api'
import formValidation from '@core/comp-functions/forms/form-validation'
import axios from 'axios'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// eslint-disable-next-line no-unused-vars
import _ from 'lodash'
import decryptData from '@/store/auth/decryptData'

export default {
  components: {},
  data() {
    return {
      id: this.$store.state.generalIds.id,
      required,
      loader: false,
      validationErrors: {},
      errors: {},
      optionsStatusId: [],
      allCertificates: [],
      optionsStatus: [],
      loaderStatus: false,
    }
  },

  setup() {
    const { getValidationState } = formValidation()
    // eslint-disable-next-line camelcase

    const requestForm = ref({
      certificatesGroup: '',
      certificate_id: '',
    })
    return {
      getValidationState,
      requestForm,
    }
  },
  computed: {
    selectedCertificateGroups() {
      if (!this.requestForm.certificatesGroup) return []

      const group = this.allCertificates.find(g => g.value === this.requestForm.certificatesGroup)
      if (group) {
        return [{
          ...group,
          certificates: group.certificates.map(certif => ({
            value: certif.id,
            text: `${certif.name.ar} - ${certif.name.en}`,
          })),
        }]
      }
      return []
    },
  },
  mounted() {
    this.getEngineerStatus()
    this.showCertificates()
  },
  methods: {
    getEngineerStatus() {
      this.loader = true
      axios
        .get('certificate')
        .then(res => {
          this.allCertificates = decryptData(res.data.payload).data.groups.map(item => ({
            value: item.name.ar,
            text: `${item.name.ar} - ${item.name.en}`,
            certificates: item.certificates,
          }))
          this.optionsStatusId = this.allCertificates.map(item => ({
            value: item.value,
            text: item.text,
          }))
        })
        .finally(() => {
          this.loader = false
        })
    },
    showCertificates() {
      if (this.$route.params.id) {
        axios.get(`/requests/${this.$route.params.id}/short-info`).then(res => {
          const userData = decryptData(res.data.payload).data.request.certificates_groups[0]
          this.requestForm.certificatesGroup = userData.name.ar
          this.requestForm.certificate_id = userData.certificates[0].id
        })
      } else {
        return false
      }
      return true
    },
    SetRequest() {
      this.loader = true
      axios
        .post(`requests/${this.$route.params.id}/assign`, this.requestForm)
        .then(res => {
          if (res.status === 200 || res.status === 201) {
            this.$router.push({ name: 'requests' })
            this.$toasted.show('Updated Successfully', {
              position: 'top-center',
              duration: 3000,
            })
          }
        })
        .catch(error => {
          if (error.response.status) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `${error.response.data.message}`,
                icon: 'BellIcon',
                variant: 'error',
              },
            })
          }
        })
        .finally(() => {
          this.loader = false
        })
    },
  },
}
</script>

  <style lang="scss">
  @import "@core/scss/vue/libs/vue-select.scss";
  </style>
